import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../cart.service';
import { PathEnum } from '../../enums/path-enum';
import { Api } from '../../services/api.service';
import { SpinnerComponent } from '../../page-elements/spinner/spinner.component';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';

@Component({
  standalone: true,
  selector: 'app-check',
  templateUrl: './check.component.html',
  imports: [SpinnerComponent],
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {
  private messages: MessageJson;

  constructor(
    private api: Api,
    private router: Router,
    private cartService: CartService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
    this.api.validateCart().subscribe((response) => {
      this.cartService.setCart(response.cart);
      localStorage.removeItem('cart');
      if (response.cartEmpty) {
        if (this.messages.mainDomain) {
          window.open(this.messages.mainDomain, '_self');
        } else {
          if (this.messages.mainDomain) {
            window.open(this.messages.mainDomain, '_self');
          } else {
            this.router.navigate(['/'], { replaceUrl: true });
          }
        }
      } else if (response.merged) {
        this.router.navigate(['warenkorb'], {
          queryParams: { merged: true },
          replaceUrl: true
        });
      } else if (!response.validCustomerStatus || response.taxNumberRequired) {
        this.router.navigate([PathEnum.orderCustomer], { replaceUrl: true });
      } else if (this.cartService.getCart().verificationStep) {
        this.router.navigate([PathEnum.orderFileUpload], { replaceUrl: true });
      } else if (this.cartService.getCart().paymentStep) {
        this.router.navigate([PathEnum.paymentSelection], { replaceUrl: true });
      } else {
        this.router.navigate([PathEnum.orderSummary], { replaceUrl: true });
      }
    });
  }
}
