<div class="container-fluid search-background p-4">
  <div class="container-header bg-transparent">
    <div class="row">
      <div class="col-12 d-md-block d-lg-none search-slogan-mobile">
        <h1 class="claim" [innerHTML]="messages.startpageClaim"></h1>
      </div>
      <div class="col-12 col-md-11 col-lg-8 col-xl-7 cat-row border-radius">
        <ul
          ngbNav
          #nav="ngbNav"
          class="cross-link nav-justified"
          (navChange)="onNavChange($event)"
        >
          <li ngbNavItem="cross-link-primary" class="cross-link-primary">
            <a ngbNavLink href="#">
              <div class="cross-link-content">
                <img
                  class="logo-primary"
                  [ngSrc]="messages.primaryCrossLinkLogo"
                  height="16"
                  width="31"
                  [alt]="messages.primaryCrossLink"
                />{{ messages.primaryCrossLink }}
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row content-card">
                <div class="col-md-5 d-md-block" #viewCategory>
                  <div class="col-12 ps-2 pt-2 text-secondary d-md-none fw-bold">
                    {{ messages.chooseScope }}
                  </div>
                  <div class="list-group mt-1">
                    <button
                      *ngFor="let category of topCategories"
                      appActivetoggle
                      (click)="setTopCat(category.id, category.name)"
                      (keydown)="setTopCat(category.id, category.name, $event)"
                      class="list-group-item list-group-item-action"
                      [ngClass]="{ activated: category.id === selectedScopeId }"
                    >
                      {{ category.name }}
                    </button>
                    <div
                      *ngIf="furtherCathegories.length > 0"
                      ngbDropdown
                      class="d-inline-block mx-auto w-100"
                    >
                      <button
                        appActivetoggle
                        class="list-group-item list-group-item-action"
                        [ngClass]="{ activated: isFurtherCategory }"
                        id="furtherCat"
                        ngbDropdownToggle
                      >
                        {{ furtherCategoryText }}
                      </button>
                      <div
                        ngbDropdownMenu
                        aria-labelledby="furtherCat"
                        class="w-100"
                      >
                        <button
                          *ngFor="let fCategory of furtherCathegories"
                          class="dropdown-item list-group-item list-group-item-action"
                          (click)="selectFurtherCat(fCategory.id)"
                          (keydown)="selectFurtherCat(fCategory.id, $event)"
                        >
                          {{ fCategory.name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 d-md-block search-form" #viewFilter>
                  <form [formGroup]="searchForm" class="mx-2">
                    <button
                      (click)="showCategoryAndHideFilterView()"
                      class="btn btn-outline-primary back-to-cat-btn d-md-none"
                    >
                      {{ messages.backToScopeSelection }}
                    </button>

                    <div *ngIf="showTypeSelection()" class="row radio-form" role="radiogroup">
                      <fieldset class="pb-0">
                        <legend>{{ messages.startpageYourSearch }}:</legend>
                        <div
                          *ngFor="let type of getTypes() | keyvalue"
                          class="form-check first-radio"
                        >
                          <input
                            role="radio"
                            aria-label="{{ type.key }}"
                            formControlName="radio"
                            type="radio"
                            id="{{ type.key }}"
                            name="radio"
                            class="form-check-input"
                            value="{{ type.key }}"
                          />
                          <label
                            class="custom-control-label form-label"
                            for="{{ type.key }}"
                          >{{ type.value }}</label
                          >
                        </div>
                      </fieldset>
                    </div>
                    <div *ngIf="searchForm.controls.radio.value === 'EDUCATION' ||
                        searchForm.controls.radio.value === 'RENO'"
                        class="row radio-form"                    >
                      <div class="col-12 p-0" role="radiogroup">
                        <fieldset class="pb-0">
                          <legend>{{ messages.filterLabelCoursetype }}:</legend>
                          <div class="row mx-1">
                            <div class="form-check col-12 col-sm-6">
                              <input
                                formControlName="courseType"
                                role="radio"
                                type="radio"
                                id="rc0"
                                name="courseType"
                                class="form-check-input"
                                value="ALL"
                              />
                              <label
                                class="custom-control-label form-label"
                                for="rc0"
                              >{{ messages.filterAll }}</label
                              >
                            </div>
                            <div
                              *ngFor="
                                let courseType of config.getCourseTypes()
                                  | keyvalue
                              "
                              class="form-check col-12 col-sm-6"
                            >
                              <input
                                formControlName="courseType"
                                type="radio"
                                id="{{ courseType.key }}"
                                name="courseType"
                                class="form-check-input"
                                value="{{ courseType.key }}"
                              />
                              <label
                                class="custom-control-label form-label"
                                for="{{ courseType.key }}"
                              >{{ courseType.value }}</label
                              >
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div
                      [ngClass]="{ 'd-none': !showCityFilter() }"
                      class="row"
                    >
                      <fieldset>
                        <legend>{{ messages.startpageCity }}:</legend>
                        <div class="col-12 p-0">
                          <app-surrounding></app-surrounding>
                        </div>
                      </fieldset>
                    </div>

                    <div
                      [ngClass]="{ 'd-none': !showTimeFilter() }"
                      class="row"
                    >
                      <fieldset>
                        <legend>{{ messages.startpageTime }}:</legend>
                        <div class="row">
                          <div
                            class="from-date col-12 col-sm-6 d-inline-block pe-sm-1"
                          >
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <div class="d-flex">
                                  <button
                                    class="date-button btn btn-outline-primary"
                                    (click)="d.toggle(); d2.close()"
                                    type="button"
                                  >
                                    <fa-icon
                                      [icon]="faCalendarAlt"
                                    ></fa-icon>
                                    &nbsp;{{ messages.filterDateFrom }}
                                  </button>
                                </div>
                                <input
                                  (click)="d.toggle(); d2.close()"
                                  class="form-control date-input"
                                  placeholder="TT.MM.JJJJ"
                                  formControlName="dateFrom"
                                  aria-label="from"
                                  ngbDatepicker
                                  #d="ngbDatepicker"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="to-date col-12 col-sm-6 d-inline-block ps-sm-1 mt-2 mt-sm-0"
                          >
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <div class="d-flex">
                                  <button
                                    class="date-button btn btn-outline-primary"
                                    (click)="d2.toggle(); d.close()"
                                    type="button"
                                  >
                                    <fa-icon
                                      [icon]="faCalendarAlt"
                                    ></fa-icon>
                                    &nbsp;{{ messages.filterDateTo }}
                                  </button>
                                </div>
                                <input
                                  (click)="d2.toggle(); d.close()"
                                  class="form-control date-input"
                                  placeholder="TT.MM.JJJJ"
                                  formControlName="dateTo"
                                  aria-label="to"
                                  ngbDatepicker
                                  #d2="ngbDatepicker"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div class="d-flex gap-2 my-2">
                      <button
                        [appRequestSpinner]="PathEnum.search"
                        [disabled]="!searchForm.valid"
                        type="submit"
                        class="btn btn-primary btn-lg btn-search"
                        (click)="onSearchSubmit()"
                      >
                        <fa-icon
                          [icon]="faSearch"
                          class="button-icon"
                        ></fa-icon>
                        <span *ngIf="searchForm.valid">{{
                          messages.filterButtonSearch
                          }}</span>
                        <span *ngIf="!searchForm.valid">{{
                          messages.chooseScope
                          }}</span>
                      </button>
                      <button
                        aria-label="Suche zurücksetzen"
                        class="btn btn-light border-primary text-primary btn-sm btn-reset"
                        (click)="resetFilter()"
                      >
                        <fa-icon
                          [icon]="faUndo"
                          [fixedWidth]="true"
                          class="button-icon m-0"
                        ></fa-icon>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="cross-link-secondary" class="cross-link-secondary">
            <a class="nav-link"
              [href]="
                messages.secondaryCrossLinkUrl
                  ? messages.secondaryCrossLinkUrl
                  : '#'
              "
              target="_blank"
            >
              <div class="cross-link-content">
                <img
                  class="logo-external"
                  [ngSrc]="messages.secondaryCrossLinkLogo"
                  height="16"
                  width="31"
                  [alt]="messages.secondaryCrossLink"
                />{{ messages.secondaryCrossLink }}
              </div>
            </a>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
      <div class="d-none d-lg-block col-lg-4 col-xl-5 search-slogan-desktop">
        <h1
          class="align-middle claim"
          [innerHTML]="messages.startpageClaim"
        ></h1>
      </div>
    </div>
  </div>
</div>
